html,
body {
  padding: 0px;
  margin: 0px;
  font-family: sans-serif;
  height: 100%;
  width: 100%;
}

.App {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}
.brdr-all {
  border: solid;
  border-width: 1px;
  border-color: lightgray;
  border-radius: 5px;
}
.brdr-btm {
  border-bottom: solid;
  border-width: 1px;
  border-color: lightgray;
}
.brdr-top {
  border-top: solid;
  border-width: 1px;
  border-color: lightgray;
}
.brdr-right {
  border-right: solid;
  border-width: 1px;
  border-color: lightgray;
}

.nav-cont {
  width: 100vw;
  height: 45px;
  margin: 0px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: green;
  color: rgb(22, 51, 105);
}

.logo-pic {
  width: 50px;
  height: 30px;
}
.dep-icon-cont {
  width: 120px;
  height: 70px;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  color: rgb(212, 212, 212);
  font-size: 14px;
  margin-bottom: 10px;
}
.dep-icon-cont:hover {
  margin-left: 10px;
  transition: 0.4s;
  background-color: gray;
}
.dep-icon-img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

.ext-cont {
  display: inline-flex;
  width: 100%;
  position: absolute;
  bottom: 0px;
  top: 45px;
  overflow: hidden;
}

.sideBar {
  height: 100%;
  margin: 0;
  padding: 0;
}

.side-title {
  text-decoration: none;
  color: white;
  display: inline-flex;
  margin: 0;
}
.side-title2 {
  text-decoration: none;
  color: rgb(220, 220, 220);
  display: inline-flex;
  font-size: 14px;
  margin-left: 40px;
}

.side-title-cont {
  font-family: sans-serif;
  padding: 8px;
}

.side-title-cont:hover {
  padding-left: 15px;
  transition: 0.4s;
}

.side-title-cont2 {
  font-family: sans-serif;
  padding: 8px;
}

.menu-icon {
  margin-top: 8px;
  margin-right: 5px;
}

.sub-menu {
  width: 100%;
  min-width: 300px;
}
.sub-menu-h5 {
  background-color: rgb(240, 240, 240);
  margin: 0;
  padding: 10px 20px;
}
.sub-menu-p {
  font-size: 12px;
  color: rgb(90, 90, 90);
  width: 100%;
  cursor: pointer;
  display: inline-flex;
}
.sub-menu-p:hover {
  background-color: lightgray;
}
.dlt-icon:hover {
  color: green;
}

.main-cont-cont {
  background-color: rgb(245, 245, 245);
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 10px;
}

.cont-all {
  background-color: white;
  border-style: solid;
  border-radius: 5px;
  border-color: lightgray;
  border-width: 1px;
}
.cont-left {
  padding: 10px 20px;
  background-color: white;
  border-left: solid;
  border-color: lightgray;
  border-width: 1px;
}
.gray-h {
  max-width: "100%";
  background-color: "gray";
  margin: "0";
  color: "white";
}
.cont2 {
  width: 90%;
  text-align: left;
  margin: auto;
}

.pa-h {
  color: gray;
  width: 100%;
  text-align: center;
}

.txt-input {
  padding: 10px 15px 10px 15px;
  width: 90%;
  border-style: solid;
  border-color: lightgray;
  border-width: 1px;
  border-radius: 3px;
  margin: 5px 0px 5px 0px;
}

.txt-input:focus {
  outline: 1px solid rgb(49, 131, 255);
}

.btn1 {
  cursor: pointer;
  width: 100%;
  margin-top: 5px;
  padding: 10px;
  color: white;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(57, 148, 45);
  border-radius: 3px;
  background-color: rgb(57, 148, 45);
}

.btn1:hover {
  color: rgb(57, 148, 45);
  background-color: white;
  transition: 0.4s;
}

.btn1-dis {
  cursor: pointer;
  width: 100%;
  margin-top: 5px;
  padding: 10px;
  color: gray;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  border-radius: 3px;
  font-weight: bold;
  background-color: lightgray;
}

.btn1:hover #dlt-icon {
  color: rgb(57, 148, 45);
  transition: 0.4s;
}

.btn2 {
  cursor: pointer;
  width: 100%;
  margin-top: 5px;
  padding: 10px;
  color: rgb(57, 148, 45);
  border-style: solid;
  border-width: 1px;
  border-color: rgb(57, 148, 45);
  border-radius: 3px;
  font-weight: bold;
  background-color: white;
}

.btn2:hover {
  color: white;
  background-color: rgb(57, 148, 45);
  transition: 0.4s;
}

.reg-err {
  margin: 0px;
  padding: 0px;
  color: red;
  font-size: 14px;
  display: none;
}

.pa-reg-suc-cont {
  background-color: white;
  width: 50%;
  max-width: 500px;
  margin: 15px;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
}

.search-cont {
  width: 500px;
  background-color: white;
  margin: 10px;
  padding: 15px;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  border-radius: 5px;
  text-align: center;
  position: relative;
  top: 0;
}

.cus-h {
  color: gray;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.search-input {
  padding: 10px 25px;
  width: 300px;
  border-style: solid;
  border-color: lightgray;
  border-width: 1px;
  border-radius: 30px 3px 3px 30px;
  margin-right: 10px;
}

.search-input:left {
  text-transform: uppercase;
}

.search-input:focus {
  outline: 1px solid rgb(49, 131, 255);
}

.btn-loading {
  display: none;
}

.search-btn {
  cursor: pointer;
  width: 100px;
  padding: 10px;
  color: white;
  background-color: rgb(49, 131, 255);
  border-style: solid;
  border-width: 1px;
  border-color: rgb(49, 131, 255);
  border-radius: 3px 30px 30px 3px;
  font-weight: bold;
}

.search-btn:hover {
  color: rgb(49, 131, 255);
  background-color: white;
  transition: 0.4s;
}

.search-cont2 {
  position: absolute;
  top: 140px;
  bottom: 0px;
  background-color: white;
  width: 100%;
  max-width: 750px;
  margin: 10px;
  border-width: 1px;
  border-color: lightgray;
  border-style: solid;
  border-radius: 5px;
  overflow-y: scroll;
  display: none;
}

.pa-list {
  list-style: none;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  border-radius: 3px;
  margin: 5px;
  display: inline-flex;
  width: auto;
  padding: 5px 15px;
  cursor: pointer;
}

.pa-list:hover {
  background-color: rgb(245, 245, 245);
}

.list-hover:hover {
  background-color: rgb(176, 176, 176);
}

.med-rec-cont {
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-color: white;
  width: 80%;
  margin: 15px;
  border-width: 1px;
  border-color: lightgray;
  border-style: solid;
  border-radius: 5px;
  overflow-y: scroll;
}

.med-rec-h-cont {
  width: 100%;
  background-color: gray;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: lightgray;
  display: inline-flex;
  padding: 0px;
  border-radius: 5px 5px 0px 0px;
  position: sticky;
  top: 0px;
}

.med-rec-h {
  color: white;
  margin: 0px;
  border-right-style: solid;
  border-width: 1px;
  border-color: lightgray;
  font-size: 20px;
  height: 100%;
  padding: 15px 40px;
}

.med-rec-card-no {
  margin: 0px;
  padding: 17px 20px;
}

.med-rec-cont-cont {
  padding: 0px;
}
.med-rec-info {
  display: inline-flex;
  border-bottom: solid;
  border-color: lightgray;
  border-width: 1px;
  width: 95%;
  margin: 0;
  padding: 10px 20px;
}
.med-rec-label {
  font-size: 14px;
  width: 130px;
  margin: 0px;
  padding: 0px;
}

.med-rec-p {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  width: 300px;
}

.btn3 {
  cursor: pointer;
  width: 20%;
  padding: 10px;
  color: white;
  background-color: rgb(57, 148, 45);
  border-style: solid;
  border-width: 1px;
  border-color: rgb(57, 148, 45);
  border-radius: 3px;
  font-weight: bold;
}

.btn3:hover {
  color: rgb(57, 148, 45);
  background-color: white;
  transition: 0.4s;
}

.btn4 {
  cursor: pointer;
  width: 20%;
  padding: 10px;
  color: rgb(49, 131, 255);
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(49, 131, 255);
  border-radius: 3px;
  font-weight: bold;
}

.btn4:hover {
  color: white;
  background-color: rgb(49, 131, 255);
  transition: 0.4s;
}

.med-rec-cont2 {
  width: auto;
  padding: 10px;
}

.med-rec-list {
  width: 190px;
  height: 250px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  cursor: pointer;
  margin: 10px;
  background-color: rgb(213, 231, 255);
  text-decoration: none;
  color: black;
}

.med-rec-list:hover .med-rec-list2 {
  background-color: rgb(245, 245, 245);
  border-color: rgb(49, 131, 255);
  transition: 0.4s;
}

.med-rec-list2 {
  width: auto;
  height: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  border-radius: 15px 0px 0px 0px;
  cursor: pointer;
  background-color: white;
  overflow: hidden;
}

.med-rec-detail-cont {
  background-color: white;
  width: auto;
  margin: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  border-radius: 5px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  overflow-y: scroll;
}

.med-rec-detail-cont2 {
  width: 500px;
  height: 200px;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  border-radius: 5px;
  margin: 10px;
}

.med-detail-p {
  padding: 10px;
  overflow-y: scroll;
  height: 120px;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.med-detail-p::-webkit-scrollbar {
  display: none;
}

.dialog-ext {
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
  position: fixed;
  z-index: 1;
}

.dialog-cont {
  background-color: white;
  width: 60%;
  max-width: 500px;
  margin: 20px auto;
  border-style: solid;
  border-color: lightgray;
  border-width: 1px;
  border-radius: 5px;
}

.link2 {
  width: 300px;
  background-color: white;
  border-bottom: solid;
  border-width: 1px;
  border-color: lightgray;
  border-radius: 3px;
  font-size: 18px;
  padding: 10px 30px;
  margin: 10px;
  cursor: pointer;
}

.link2:hover {
  background-color: rgb(66, 66, 66);
  color: white;
}

.link3 {
  width: 300px;
  border-bottom: solid;
  border-width: 1px;
  border-color: lightgray;
  border-radius: 3px;
  font-size: 18px;
  padding: 10px 30px;
  margin: 10px;
  cursor: pointer;
  font-weight: bold;
}
.link3:hover {
  background-color: black;
  color: "white";
}

.cont3 {
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  border-radius: 5px;
}

.full-cont {
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  border-radius: 5px;
  position: absolute;
  width: 80%;
  top: 10px;
  bottom: 10px;
  margin-right: 100px;
  margin-left: 10px;
  padding: 10px;
}

.tbl-h-cont {
  display: "inline-flex";
  background-color: "rgb(240, 240, 240)";
  margin: "20px";
  padding: "10px 20px";
}
.tbl-h {
  color: "gray";
  margin: "0px";
  padding: "0px";
}
.tbl-p-cont {
  display: "inline-flex";
  margin: "0 20px";
  padding: "10px 20px";
  border-bottom: "solid";
  border-color: "lightgray";
  border-width: "1px";
}
.tbl-p {
  margin: "0px";
  padding: "0px";
  font-size: "14px";
}

.dash-btn {
  background-color: rgb(235, 235, 235);
  cursor: pointer;
}
.dash-btn:hover {
  background-color: rgb(170, 255, 170);
}

table {
  background-color: "white";
}

th {
  color: gray;
  background-color: rgb(240, 240, 240);
  font-size: 14px;
  padding: 5px 15px;
}
td {
  font-size: 14px;
  padding: 5px 15px;
  border-bottom: solid;
  border-width: 1px;
  border-color: lightgray;
}

.print-btn {
  opacity: 0.5;
}
.print-btn:hover {
  opacity: 1;
  transition: 0.4s;
}

.resource-suggestion:hover {
  background-color: green;
  color: white;
}




